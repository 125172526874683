import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/lib/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
      iconfont: 'mdi',
    },
    lang: {
      locales: { de },
      current: 'de',
    },
    breakpoint: {
      mobileBreakpoint: 'sm'  // width below 960 is considered mobile
    },
    theme: {
      themes: {
        light: {
          primary: '#3A4257',
          secondary: '#F0DCBC',
          tertiary: '#FFF6E6',
          accent: '#ED6A5A',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
        },
      },
    },
});
