<template>
  <v-container v-if="project_data">

    <v-skeleton-loader v-if="isLoading" class="mx-auto" type="article@4 " style="width: calc(90vw); max-width: 800px; overflow:hidden" />

    <v-card v-else flat color="primary" class="white--text" style="width: calc(90vw); max-width: 800px; overflow:hidden">

      <v-card-title class="justify-center white--text">
        <v-row>
          <v-col cols="10">
            {{project_data.name}}
          </v-col>
          <v-col cols="2">
            <v-btn @click="closeProjectDetail(true)" fab x-small dark fixed style="right:26px;"><v-icon color="secondary">mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text v-if="news.length > 0" class="white--text">
        <v-row align="center" v-for="(entry, index) in news" :key="index">
          <v-col cols="8" md="12">
            <v-icon v-if="entry.type == 'info'" class="px-2" color="info">mdi-information-outline</v-icon>
            <v-icon v-if="entry.type == 'warn'" class="px-2" color="warning">mdi-alert-outline</v-icon>
            <v-icon v-if="entry.type == 'alert'" class="px-2" color="error">mdi-alert-circle-outline</v-icon>
            <b>{{entry.headline}}</b>
          </v-col>
          <v-col cols="4" md="12">
            {{entry.content}}
          </v-col>
        </v-row>
        <hr />
      </v-card-text>

      <!--<v-card-subtitle class="white--text" >{{project_data.organisation.name}}</v-card-subtitle>-->
      <v-card-text class="white--text wysiwyg-text" v-html="project_data.description" />

      <v-card-text class="white--text">
        <v-icon class="mr-1" color="secondary">mdi-ticket</v-icon>{{$t('local-offers')}}<br />
        <TagIcon :tagId="project_data.main_tag.id" />
        <TagIcon :tagId="tag.tags_id" v-for="(tag, index) in project_data.tags" :key="index" />
      </v-card-text>
      
      <v-card-text v-if="project_data.opening_days" class="white--text">
        <v-icon class="mr-1" color="secondary">mdi-clock-outline</v-icon>{{$t('opening-hours')}} <br />
        <b>
          <span v-if="project_data.opening_days.includes('monday')">{{$t('monday-short')}} &nbsp;</span>
          <span v-if="project_data.opening_days.includes('tuesday')">{{$t('tuesday-short')}} &nbsp;</span>
          <span v-if="project_data.opening_days.includes('wednesday')">{{$t('wednesday-short')}} &nbsp;</span>
          <span v-if="project_data.opening_days.includes('thursday')">{{$t('thursday-short')}} &nbsp;</span>
          <span v-if="project_data.opening_days.includes('friday')">{{$t('friday-short')}} &nbsp;</span>
          <span v-if="project_data.opening_days.includes('saturday')">{{$t('saturday-short')}} &nbsp;</span>
          <span v-if="project_data.opening_days.includes('sunday')">{{$t('sunday-short')}} &nbsp;</span>
        </b>
        <span class="wysiwyg-text" v-if="project_data.opening_text" v-html="project_data.opening_text"></span>
      </v-card-text>

      <v-card-text v-if="project_data.opening_events" class="white--text" >
        <v-icon class="mr-1" color="secondary">mdi-calendar-star</v-icon>{{$t('events')}} <br />
        <div class="mb-2" v-for="(item, index) in project_data.opening_events" :key="index">
          <b>{{item.name}}</b>
          {{$t(item.day_of_week)}} {{item.time}}
          <br />
          {{item.description}}
        </div>
      </v-card-text>

      <v-card-text v-if="project_data.spoken_languages" class="white--text">
        <v-icon class="mr-1" color="secondary">mdi-translate</v-icon>{{$t('spoken-languages')}}<br />
         {{project_data.spoken_languages.join(', ')}}
      </v-card-text>

      <v-card-text class="white--text text-h6">
        <v-icon class="mr-1" color="secondary">mdi-human-greeting-proximity</v-icon>{{$t('contact-information')}} <br />
        <v-row class="text-body-2">
          <v-col cols="12" md="6">
            {{project_data.contact_name}}
            {{project_data.street}}<br />
            {{project_data.zipcode + ' ' + project_data.city}}<br />
            <div v-if="project_data.phone_number && !$vuetify.breakpoint.mobile">{{project_data.phone_number}}</div>
            <div v-if="project_data.email && !$vuetify.breakpoint.mobile">{{project_data.email}}</div>
          </v-col>
          <v-col v-if="project_data.contact" cols="12" md="6">
            <p class="textbox-text" v-html="project_data.contact" />
          </v-col>
        </v-row>
      </v-card-text>
      
      <v-card-text v-if="translatedFAQ.length > 0" class="white--text">
        <v-icon class="mr-1" color="secondary">mdi-frequently-asked-questions</v-icon> {{$t('faq')}}<br />
        <v-expansion-panels accordion>
          <v-expansion-panel v-for="(entry, index) in translatedFAQ" :key="index">
            <v-expansion-panel-header>{{entry.question}}</v-expansion-panel-header>
            <v-expansion-panel-content class="mx-2 wysiwyg-text" v-html="entry.answer" />
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col v-if="isOnline" cols="12" md="6">
            <v-btn v-if="project_data.location" block color="tertiary" class="black--text" rounded :href="'https://www.google.de/maps/search/?api=1&query=' + project_data.location.coordinates[1] + ',' + + project_data.location.coordinates[0]" target="_blank">
              <v-icon left dark>mdi-map-marker-outline</v-icon>
              {{$t('directions')}}
            </v-btn>
          </v-col>
          <v-col v-if="isOnline && project_data.website" cols="12" md="6">
            <v-btn block color="tertiary" class="black--text" rounded :href="improveURL(project_data.website)" target="_blank">
              <v-icon left dark>mdi-earth</v-icon>
            {{$t('website')}}
            </v-btn>
          </v-col>
          <v-col v-if="isOnline && project_data.email" cols="12" md="6">
            <v-btn block color="tertiary" class="black--text" rounded :href="'mailto:' + project_data.email" target="_blank">
              <v-icon left dark>mdi-email-outline</v-icon>
              {{$t('email')}}
            </v-btn>
          </v-col>
          <v-col v-if="project_data.phone_number" cols="12" md="6">
            <v-btn block color="tertiary" class="black--text" rounded :href="'tel:' + project_data.phone_number" target="_blank">
              <v-icon left dark>mdi-phone-outline</v-icon>
              {{$t('phone')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </v-container>
</template>

<script>

import TagIcon from '@/components/TagIcon'
import { VueOfflineMixin } from 'vue-offline'

export default {
  name: 'ProjectDetail',
  props:['project', 'projectId'],
  mixins: [VueOfflineMixin],
  components: {
    TagIcon,
  },
  data: () => ({
    isLoading: true,
    project_data: null,
    news: [],
  }),
  async mounted() {
    
    this.project_data = this.project
    if (this.isOnline) {
      
      if (this.projectId != null) this.project_data = await this.$root.GlobalSearch.getProjectById(this.projectId)

      let filterValues =  { "_and": [
                            {"project_id":{"id": {"_eq":this.project_data.id}}},
                            {"start_date": {"_lt":"$NOW"}},
                            {"end_date": {"_gt":"$NOW"}}
                          ]
                        }

      await this.$client.items("news").readByQuery({ fields: ['*.*'], sort: 'sort', filter: filterValues}) 
      .then(data => {this.news = data.data})

      this.isLoading = false
    } else {
      this.isLoading = false
    }
  },
  methods: {
    closeProjectDetail (event) {
      this.$emit('closed', 'true')
    },
    improveURL(url) {
      if (!/^https?:\/\//i.test(url)) {
        url = 'http://'  + url;
      }

      return url
    }
  },
  computed: {
    translatedFAQ: function() {
      if (!this.project_data.translations) return []

      let userLanguage = this.$store.state.language

      if(userLanguage == 'de') userLanguage = 'de-DE'
      if(userLanguage == 'en') userLanguage = 'en-US'

      let translations = this.project_data.translations.find(element => element.languages_code.code == userLanguage)
      
      if (!translations) return []

      return translations.faqs
    }
  }
}
</script>

<style lang="scss">


</style>