export default class Tags {

  constructor(DirectusInstance) {
    this.client = DirectusInstance
    this.tagCache = []
  }

  setParameter (parameter) {
    this.parameter = parameter
  }

  async loadTags() {
    
    await this.client.items("tags").readByQuery({ fields: ['id, icon, name, searchable, translations.languages_code, translations.name'], filter: {'tenant_id': process.env.VUE_APP_PROJECT_TENANT_ID }})
    .then(data => {
      data.data.forEach(element => {
        this.tagCache[element.id] = element
      })
    })

    return null
  }

  async getTagById(tagId) {
    if(Object.keys(this.tagCache).length == 0) await this.loadTags()

    return this.tagCache[tagId]
  }

  async getAllSearchableTags() {
    if(Object.keys(this.tagCache).length == 0) await this.loadTags()

    return this.tagCache.filter(element => element.searchable == true)
  }
}
