<template>
  <v-app>
    <v-app-bar absolute v-if="isOnline && !$vuetify.breakpoint.mobile" color="primary">
      <router-link to="/">
        <v-img :src="require('@/assets/logos/' + projectDetails.logo)" height="50px" max-width="160px" contain />
      </router-link>
      <v-spacer />

      <v-select dense dark outlined color="white" small style="max-width: 160px; margin: auto; padding-top: 8px" :items="languages" v-model="language">
        <template v-slot:selection="{ item }">
            <img :src="item.img" height="10px" width="16px" :alt="item.label" /><span>&nbsp;{{item.label}}</span>
        </template>
        <template v-slot:item="{ item }">
            <img :src="item.img" height="10px" width="16px" :alt="item.label" />&nbsp;{{item.label}}
        </template>
      </v-select>

      <v-btn v-if="projectDetails.menu_authority" @click="clickMenu('/authority')" text color="white" class="text-h6" style="text-transform:none">{{$t('authority')}}</v-btn>
      <v-btn @click="clickMenu('/documents')" text color="white" class="text-h6" style="text-transform:none">{{$t('cabinet')}}</v-btn>
      <v-btn @click="clickMenu('/faq')" text color="white" class="text-h6" style="text-transform:none">{{$t('faq')}}</v-btn>
      <!--<v-btn text class="text-h6" color="white" style="text-transform:none" :href="'mailto:'+projectDetails.mail" target="_blank">{{$t('contact')}}</v-btn>-->
      <v-btn @click="searchFavorites()" text class="text-h6"  color="white" style="text-transform:none" :disabled="hasFavorites">{{$t('my-favorites')}}</v-btn>
      <v-btn @click="openMap()" rounded color="secondary" class="text-h6 black--text" style="text-transform:none"><v-icon>mdi-map</v-icon>{{$t('find-offers')}}</v-btn>
    </v-app-bar>

    <v-main style="background: linear-gradient(180deg, rgba(240, 220, 188, 0) 0%, #F0DCBC 100%);">
      <keep-alive>
        <router-view class="pa-0" :style="'margin-top: ' + ($vuetify.breakpoint.mobile ? '0px' : '70px' )  + '; overflow: auto; height: calc(100vh - ' + ($vuetify.breakpoint.mobile ? '62' : '200') + 'px);'"/>
      </keep-alive>
    </v-main>

    <v-footer absolute v-if="isOnline && !$vuetify.breakpoint.mobile" style="max-height: 116px" color="primary" >
      <vue-cookie-accept-decline disableDecline elementId="consent">
        <template v-slot:message>
          <p>
            Bestimmte Funktionen dieser Website benötigen Cookies um dir die bestmögliche Erfahrung zu bieten.
            Dazu gehören das Speichern deiner Favoriten.
          </p>
          <p>
            Des Weiteren benutzen wir Matomo um den Traffic dieser Website anonym zu analysieren, diese Daten teilen wir nicht mit Dritten.
          </p>
          <p>
            Wir benutzen sentry.io um Fehler / Probleme auf dieser Website zu erfassen.
          </p>
          <p>
            Mehr dazu erfährst du auf unserer <a href="/privacy" target="_blank">Datenschutz-Seite</a>.
          </p>
        </template>
        <template v-slot:declineContent>
          Nein Danke
        </template>
        <template v-slot:acceptContent>
          Passt!
        </template>

      </vue-cookie-accept-decline>
      <v-row justify="center" no-gutters class="white--text mt-4">
        <v-col cols="3">
          {{projectDetails.name}}
          <p>
            <a :href="'tel:' + projectDetails.phone" target="_blank" style="color:white">{{projectDetails.phone}}</a><br />
            <a :href="'mailto:'+projectDetails.mail" target="_blank" style="color:white">{{projectDetails.mail}}</a>
          </p>
        </v-col>
        
        <!--<v-col cols="2">
          <v-btn @click="openMap()" color="white" class="pa-0" text>{{$t('offers')}}</v-btn><br />
          <v-btn @click="clickMenu('/faq')" color="white" class="pa-0" text min-width="0">{{$t('faq')}}</v-btn><br />
        </v-col>
        <v-col cols="2">
          <v-btn @click="clickMenu('/commodity-contributions')" color="white" class="pa-0" text>{{$t('commodity-contributions')}}</v-btn><br />
          <v-btn @click="clickMenu('/documents')" color="white" class="pa-0" text>{{$t('cabinet')}}</v-btn><br />
        </v-col>-->
        <v-col cols="3">
          <v-btn @click="clickMenu('/your-project')" color="white" class="pa-0" text>{{$t('your-project')}}</v-btn><br />
        </v-col>
        <v-col cols="3">
          <v-btn @click="clickMenu('/about')" color="white" class="pa-0" text>{{$t('about-us')}}</v-btn><br />
          <!--<v-btn @click="clickMenu('/our-mission')" color="white" class="pa-0" text>{{$t('our-mission')}}</v-btn><br />-->
          <v-btn color="white" class="pa-0" text :href="'mailto:' + projectDetails.mail" target="_blank" >{{$t('contact')}}</v-btn><br />
        </v-col>
        <v-col cols="3">
          <v-btn @click="clickMenu('/imprint')" color="white" class="pa-0" text>{{$t('imprint')}}</v-btn><br />
          <v-btn @click="clickMenu('/privacy')" color="white" class="pa-0" text>{{$t('privacy-policy')}}</v-btn><br />
        </v-col>
      </v-row>
    </v-footer>

    <v-bottom-navigation v-if="isOnline && $vuetify.breakpoint.mobile" background-color="primary" style="height:60px; border-radius: 10px;"  grow app v-model="$root.activeNavigation">
      
      <v-btn value="home" @click="goToHome()" color="primary" >
        <svg width="31" height="31" viewBox="0 0 31 31" xmlns="http://www.w3.org/2000/svg">
          <path :fill="$root.activeNavigation == 'home' ? 'red': '#F0DCBC'" d="M27.1327 30.1546H3.55773C2.70623 30.1529 1.89008 29.8139 1.28798 29.2118C0.685872 28.6097 0.346883 27.7936 0.345231 26.9421V15.0233C0.343808 14.5786 0.434732 14.1384 0.612245 13.7307C0.789759 13.323 1.05 12.9565 1.37648 12.6546L14.6202 0.435816C14.8183 0.254869 15.0769 0.154541 15.3452 0.154541C15.6135 0.154541 15.8721 0.254869 16.0702 0.435816L29.314 12.6546C29.6415 12.957 29.9024 13.3244 30.08 13.7333C30.2576 14.1423 30.3479 14.5837 30.3452 15.0296V26.9421C30.3436 27.7936 30.0046 28.6097 29.4025 29.2118C28.8004 29.8139 27.9842 30.1529 27.1327 30.1546ZM15.3452 2.68582L2.84523 14.2296C2.73858 14.3311 2.65368 14.4533 2.59566 14.5887C2.53765 14.724 2.50773 14.8698 2.50773 15.0171V26.9421C2.50937 27.225 2.6225 27.4959 2.82257 27.696C3.02265 27.896 3.29354 28.0092 3.57648 28.0108H27.1327C27.4157 28.0092 27.6866 27.896 27.8866 27.696C28.0867 27.4959 28.1998 27.225 28.2015 26.9421V15.0233C28.2036 14.8721 28.1728 14.7223 28.1113 14.5841C28.0498 14.446 27.959 14.3229 27.8452 14.2233L15.3452 2.68582Z" />
        </svg>
      </v-btn>

      <v-btn value="map" @click="openMap()" color="primary">
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path :fill="$root.activeNavigation == 'map' ? 'red': '#F0DCBC'" d="M1.74722 30.1544C1.46377 30.1544 1.19193 30.0418 0.991498 29.8414C0.791068 29.641 0.678468 29.3692 0.678468 29.0857V3.36695C0.678077 3.12448 0.760147 2.88909 0.9112 2.69942C1.06225 2.50975 1.27331 2.37708 1.50972 2.3232L10.7847 0.15445C10.9426 0.120081 11.1062 0.120737 11.2638 0.15637C11.4215 0.192003 11.5694 0.261749 11.6972 0.3607C11.8226 0.461247 11.9236 0.588764 11.9929 0.733763C12.0621 0.878763 12.0978 1.03751 12.0972 1.1982V26.9419C12.0997 27.1794 12.023 27.4109 11.8792 27.5999C11.7355 27.7889 11.5329 27.9246 11.3035 27.9857L1.99097 30.1544C1.90998 30.1637 1.82821 30.1637 1.74722 30.1544ZM2.82222 4.2232V27.7357L9.95972 26.0857V2.5732L2.82222 4.2232Z" />
          <path :fill="$root.activeNavigation == 'map' ? 'red': '#F0DCBC'" d="M20.3285 30.1544C20.2496 30.164 20.1699 30.164 20.091 30.1544L10.791 27.9857C10.5546 27.9318 10.3435 27.7991 10.1925 27.6094C10.0414 27.4198 9.95933 27.1844 9.95972 26.9419V1.22316C9.95915 1.06247 9.99481 0.903722 10.0641 0.758722C10.1333 0.613723 10.2344 0.486206 10.3597 0.385659C10.4845 0.28289 10.6304 0.208888 10.787 0.168919C10.9437 0.128949 11.1072 0.123995 11.266 0.154409L20.5722 2.32316C20.8075 2.37826 21.0171 2.51146 21.1669 2.70101C21.3168 2.89056 21.3979 3.12529 21.3972 3.36691V29.0857C21.3978 29.2463 21.3621 29.4051 21.2929 29.5501C21.2236 29.6951 21.1226 29.8226 20.9972 29.9232C20.8059 30.0718 20.5708 30.1531 20.3285 30.1544ZM12.0972 26.0857L19.2597 27.7357V4.22316L12.0972 2.57316V26.0857Z"/>
          <path :fill="$root.activeNavigation == 'map' ? 'red': '#F0DCBC'" d="M20.3285 30.1545C20.0862 30.1531 19.8511 30.0718 19.6598 29.9232C19.5344 29.8227 19.4334 29.6952 19.3641 29.5502C19.2949 29.4052 19.2592 29.2464 19.2598 29.0857V3.36697C19.2591 3.12535 19.3402 2.89062 19.4901 2.70107C19.6399 2.51151 19.8495 2.37832 20.0848 2.32322L29.366 0.154467C29.523 0.120188 29.6855 0.120897 29.8421 0.156543C29.9988 0.19219 30.1456 0.261899 30.2723 0.360717C30.4018 0.463553 30.5058 0.594875 30.5762 0.744468C30.6467 0.894061 30.6817 1.05789 30.6785 1.22322V26.942C30.6789 27.1844 30.5968 27.4198 30.4458 27.6095C30.2947 27.7992 30.0837 27.9318 29.8473 27.9857L20.5723 30.1545C20.4913 30.1637 20.4095 30.1637 20.3285 30.1545ZM21.3973 4.22322V27.7357L28.5348 26.0857V2.57322L21.3973 4.22322Z"/>
        </svg>
      </v-btn>

      <v-btn  value="list" @click="openList()" color="primary">
        <svg width="31" height="24" viewBox="0 0 31 24" xmlns="http://www.w3.org/2000/svg">
          <path :fill="$root.activeNavigation == 'list' ? 'red': '#F0DCBC'" d="M2.15547 4.65762C3.33943 4.65762 4.29922 3.69783 4.29922 2.51387C4.29922 1.32991 3.33943 0.370117 2.15547 0.370117C0.971509 0.370117 0.0117188 1.32991 0.0117188 2.51387C0.0117188 3.69783 0.971509 4.65762 2.15547 4.65762Z"/>
          <path :fill="$root.activeNavigation == 'list' ? 'red': '#F0DCBC'" d="M28.9429 3.58889H9.65544C9.5078 3.60017 9.35942 3.58083 9.2196 3.53208C9.07979 3.48333 8.95155 3.40622 8.84293 3.30558C8.73431 3.20495 8.64765 3.08296 8.58839 2.94726C8.52913 2.81157 8.49854 2.66509 8.49854 2.51702C8.49854 2.36894 8.52913 2.22247 8.58839 2.08677C8.64765 1.95107 8.73431 1.82908 8.84293 1.72845C8.95155 1.62781 9.07979 1.5507 9.2196 1.50195C9.35942 1.4532 9.5078 1.43386 9.65544 1.44514H28.9429C29.2131 1.46578 29.4655 1.58767 29.6496 1.78641C29.8337 1.98515 29.936 2.24609 29.936 2.51702C29.936 2.78795 29.8337 3.04888 29.6496 3.24762C29.4655 3.44636 29.2131 3.56825 28.9429 3.58889Z"/>
          <path :fill="$root.activeNavigation == 'list' ? 'red': '#F0DCBC'" d="M2.15547 14.3014C1.73148 14.3014 1.317 14.1757 0.964466 13.9401C0.611928 13.7046 0.337158 13.3698 0.174903 12.978C0.0126476 12.5863 -0.0298058 12.1553 0.0529113 11.7394C0.135628 11.3236 0.339801 10.9416 0.63961 10.6418C0.939419 10.342 1.3214 10.1378 1.73725 10.0551C2.15309 9.97239 2.58413 10.0148 2.97585 10.1771C3.36757 10.3394 3.70238 10.6141 3.93793 10.9667C4.17349 11.3192 4.29922 11.7337 4.29922 12.1577C4.29757 12.7257 4.07118 13.27 3.66951 13.6717C3.26783 14.0734 2.72352 14.2998 2.15547 14.3014Z"/>
          <path :fill="$root.activeNavigation == 'list' ? 'red': '#F0DCBC'" d="M28.9429 13.2264H9.65542C9.37197 13.2264 9.10013 13.1138 8.8997 12.9133C8.69927 12.7129 8.58667 12.4411 8.58667 12.1576C8.58667 11.8742 8.69927 11.6023 8.8997 11.4019C9.10013 11.2015 9.37197 11.0889 9.65542 11.0889H28.9429C29.2264 11.0889 29.4982 11.2015 29.6986 11.4019C29.8991 11.6023 30.0117 11.8742 30.0117 12.1576C30.0117 12.4411 29.8991 12.7129 29.6986 12.9133C29.4982 13.1138 29.2264 13.2264 28.9429 13.2264Z"/>
          <path :fill="$root.activeNavigation == 'list' ? 'red': '#F0DCBC'" d="M2.15547 23.9451C1.73148 23.9451 1.317 23.8194 0.964466 23.5838C0.611928 23.3483 0.337158 23.0134 0.174903 22.6217C0.0126476 22.23 -0.0298058 21.799 0.0529113 21.3831C0.135628 20.9673 0.339801 20.5853 0.63961 20.2855C0.939419 19.9857 1.3214 19.7815 1.73725 19.6988C2.15309 19.6161 2.58413 19.6585 2.97585 19.8208C3.36757 19.983 3.70238 20.2578 3.93793 20.6103C4.17349 20.9629 4.29922 21.3774 4.29922 21.8013C4.29757 22.3694 4.07118 22.9137 3.66951 23.3154C3.26783 23.7171 2.72352 23.9434 2.15547 23.9451Z"/>
          <path :fill="$root.activeNavigation == 'list' ? 'red': '#F0DCBC'" d="M28.9429 22.8701H9.65544C9.5078 22.8814 9.35942 22.8621 9.2196 22.8133C9.07979 22.7646 8.95155 22.6875 8.84293 22.5868C8.73431 22.4862 8.64765 22.3642 8.58839 22.2285C8.52913 22.0928 8.49854 21.9463 8.49854 21.7983C8.49854 21.6502 8.52913 21.5037 8.58839 21.368C8.64765 21.2323 8.73431 21.1103 8.84293 21.0097C8.95155 20.9091 9.07979 20.832 9.2196 20.7832C9.35942 20.7345 9.5078 20.7151 9.65544 20.7264H28.9429C29.2131 20.747 29.4655 20.8689 29.6496 21.0677C29.8337 21.2664 29.936 21.5273 29.936 21.7983C29.936 22.0692 29.8337 22.3301 29.6496 22.5289C29.4655 22.7276 29.2131 22.8495 28.9429 22.8701Z"/>
        </svg>
      </v-btn>

      <v-btn value="menu" color="primary" @click="openMenu()">
        <svg width="31" height="10" viewBox="0 0 31 10" xmlns="http://www.w3.org/2000/svg">
          <path :fill="$root.activeNavigation == 'menu' ? 'red': '#F0DCBC'" d="M8.91362 5.15135C8.91362 5.9996 8.66201 6.82879 8.19061 7.534C7.71922 8.23921 7.04924 8.78875 6.26543 9.11307C5.48163 9.4374 4.61924 9.52194 3.78737 9.356C2.95551 9.19006 2.19156 8.78109 1.59219 8.18085C0.992826 7.58061 0.584975 6.81606 0.420247 5.98396C0.25552 5.15185 0.341319 4.28958 0.666788 3.50626C0.992257 2.72293 1.54277 2.05374 2.24867 1.58338C2.95456 1.11302 3.78412 0.86261 4.63237 0.863847C5.76841 0.865502 6.85734 1.31795 7.66006 2.12183C8.46277 2.92572 8.91363 4.01531 8.91362 5.15135ZM2.48862 5.15135C2.48862 5.57534 2.61435 5.98981 2.84991 6.34235C3.08547 6.69489 3.42028 6.96966 3.812 7.13191C4.20372 7.29417 4.63475 7.33662 5.0506 7.2539C5.46645 7.17119 5.84843 6.96702 6.14823 6.66721C6.44804 6.3674 6.65222 5.98542 6.73493 5.56957C6.81765 5.15372 6.7752 4.72269 6.61294 4.33097C6.45069 3.93925 6.17592 3.60444 5.82338 3.36888C5.47084 3.13333 5.05637 3.0076 4.63237 3.0076C4.06432 3.00925 3.52001 3.23563 3.11834 3.63731C2.71666 4.03898 2.49027 4.5833 2.48862 5.15135Z"/>
          <path :fill="$root.activeNavigation == 'menu' ? 'red': '#F0DCBC'" d="M19.6324 5.15134C19.6324 5.99933 19.3809 6.82827 18.9098 7.53335C18.4387 8.23842 17.7691 8.78796 16.9856 9.11247C16.2022 9.43699 15.3401 9.52189 14.5084 9.35646C13.6767 9.19102 12.9128 8.78268 12.3132 8.18306C11.7135 7.58344 11.3052 6.81948 11.1398 5.98779C10.9743 5.1561 11.0592 4.29402 11.3837 3.51059C11.7083 2.72715 12.2578 2.05753 12.9629 1.58641C13.6679 1.1153 14.4969 0.863841 15.3449 0.863841C16.4815 0.865495 17.5711 1.31774 18.3748 2.12145C19.1785 2.92515 19.6307 4.01473 19.6324 5.15134ZM13.2011 5.15134C13.2011 5.57533 13.3269 5.98981 13.5624 6.34234C13.798 6.69488 14.1328 6.96965 14.5245 7.13191C14.9162 7.29416 15.3473 7.33662 15.7631 7.2539C16.1789 7.17118 16.5609 6.96701 16.8607 6.6672C17.1605 6.36739 17.3647 5.98541 17.4474 5.56957C17.5302 5.15372 17.4877 4.72268 17.3254 4.33096C17.1632 3.93924 16.8884 3.60444 16.5359 3.36888C16.1833 3.13332 15.7689 3.00759 15.3449 3.00759C14.7768 3.00924 14.2325 3.23563 13.8308 3.6373C13.4292 4.03898 13.2028 4.58329 13.2011 5.15134Z"/>
          <path :fill="$root.activeNavigation == 'menu' ? 'red': '#F0DCBC'" d="M30.345 5.15134C30.345 5.99933 30.0936 6.82827 29.6224 7.53335C29.1513 8.23842 28.4817 8.78796 27.6983 9.11247C26.9148 9.43699 26.0528 9.52189 25.2211 9.35646C24.3894 9.19102 23.6254 8.78268 23.0258 8.18306C22.4262 7.58344 22.0178 6.81948 21.8524 5.98779C21.687 5.1561 21.7719 4.29402 22.0964 3.51059C22.4209 2.72715 22.9704 2.05753 23.6755 1.58641C24.3806 1.1153 25.2095 0.863841 26.0575 0.863841C27.1941 0.865495 28.2837 1.31774 29.0874 2.12145C29.8911 2.92515 30.3434 4.01473 30.345 5.15134ZM23.9138 5.15134C23.9138 5.57533 24.0395 5.98981 24.2751 6.34234C24.5106 6.69488 24.8454 6.96965 25.2371 7.13191C25.6289 7.29416 26.0599 7.33662 26.4757 7.2539C26.8916 7.17118 27.2736 6.96701 27.5734 6.6672C27.8732 6.36739 28.0774 5.98541 28.1601 5.56957C28.2428 5.15372 28.2003 4.72268 28.0381 4.33096C27.8758 3.93924 27.6011 3.60444 27.2485 3.36888C26.896 3.13332 26.4815 3.00759 26.0575 3.00759C25.4895 3.00924 24.9452 3.23563 24.5435 3.6373C24.1418 4.03898 23.9154 4.58329 23.9138 5.15134Z"/>
        </svg>
      </v-btn>
    </v-bottom-navigation>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{$t('offline-notification')}}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          {{$t('close')}}
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay v-if="$root.menuOpen" :dark="false" color="none" opacity="100" z-index="15" style="height: calc(100vh - 68px)">
      <v-container class="tertiary-background ma-1 pa-1" style="height: calc(88vh); width: calc(96vw); border-radius: 10px">
        <h1 class="text-center">{{$t('menu')}}</h1>
        <v-btn @click="closeMenu()" fab absolute color="primary" x-small style="top:5%; right:4%; z-index:5">
          <v-icon color="secondary">mdi-close</v-icon>
        </v-btn>

        <v-row justify="center" class="ma-1">
          <v-col cols="12">
            <v-btn text :disabled="hasFavorites" @click="searchFavorites()"><v-icon class="mr-1">mdi-star-outline</v-icon>{{$t('my-favorites')}}</v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn text @click="clickMenu('/faq')"><v-icon class="mr-1">mdi-help-circle-outline</v-icon>{{$t('faq')}}</v-btn>
          </v-col>
          
          <v-col cols="12">
            <v-btn text :href="'mailto:' + projectDetails.mail"><v-icon class="mr-1">mdi-email-outline</v-icon>{{$t('contact')}}</v-btn>
          </v-col>
      
          <!--<v-col cols="12">
            <v-btn text @click="clickMenu('/contact')">{{$t('contact')}}</v-btn>
          </v-col>-->
          <!--<v-col cols="12">{{$t('newsletter-registration')}}</v-col>-->
          <v-col cols="12">
            <v-btn text @click="clickMenu('/documents')"><v-icon class="mr-1">mdi-file-outline</v-icon>{{$t('important-documents')}}</v-btn>
          </v-col>
          <v-col cols="12"> 
            <v-btn text @click="clickMenu('/imprint')"><v-icon class="mr-1">mdi-file-document-outline</v-icon>{{$t('imprint')}}</v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn text @click="clickMenu('/privacy')"><v-icon class="mr-1">mdi-fingerprint</v-icon>{{$t('privacy-policy')}}</v-btn>
          </v-col>
          <v-col v-if="projectDetails.menu_authority" cols="12">
            <v-btn text @click="clickMenu('/authority')"><v-icon class="mr-1">mdi-help-box-multiple-outline</v-icon>{{$t('authority')}}</v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn text @click="clickInstall()"><v-icon class="mr-1">mdi-cellphone-arrow-down-variant</v-icon>{{$t('install-app')}}</v-btn>
          </v-col>
          <v-col cols="12">
            <v-select :items="languages" v-model="language" :label="$t('my-language')">
              <template v-slot:selection="{ item }">
                  <img :src="item.img" height="20px" width="32" :alt="item.label"/>&nbsp;{{item.label}}
              </template>
              <template v-slot:item="{ item }">
                  <img :src="item.img" height="20px" width="32" :alt="item.label"/>&nbsp;{{item.label}}
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
  </v-app>
</template>

<script>

import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import { VueOfflineMixin } from 'vue-offline'

export default {
  name: 'App',
  mixins: [VueOfflineMixin],
  components:{VueCookieAcceptDecline },
  data: () => ({
    projectDetails:{
                    name: process.env.VUE_APP_PROJECT_NAME,
                    phone:process.env.VUE_APP_PROJECT_PHONE,
                    mail: process.env.VUE_APP_PROJECT_MAIL,
                    logo: process.env.VUE_APP_PROJECT_LOGO,
                    menu_authority: (process.env.VUE_APP_PROJECT_MENU_ENABLE_AUTHORITY && process.env.VUE_APP_PROJECT_MENU_ENABLE_AUTHORITY != "false") ? true : false 
                    },
    oldActiveNavigation:'',
    // see: https://omniglot.com/language/names.htm for language translations
    languages: [
                {'value':'en', 'label':'English', 'img': require('@/assets/Flag_of_the_United_Kingdom.svg')}, 
                {'value':'de', 'label':'Deutsch', 'img': require('@/assets/Flag_of_Germany.svg')},
                //{'value':'ua', 'label':'українська мова', 'img': require('@/assets/Flag_of_Ukraine.svg')},
                //{'value':'es', 'label':'español', 'img': require('@/assets/Flag_of_Spain.svg')},
                //{'value':'ru', 'label':'Русский язык', 'img': require('@/assets/Flag_of_Russia.svg')},
                //{'value':'fr', 'label':'français', 'img': require('@/assets/Flag_of_France.svg')},
                //{'value':'bg', 'label':'Български', 'img': require('@/assets/Flag_of_Bulgaria.svg')},
                //{'value':'pl', 'label':'polski', 'img': require('@/assets/Flag_of_Poland.svg')}
                ],
    language: 'de',
    snackbar: false,
    timeout: 5000,
  }),
  async created() {
    this.$i18n.locale = this.$store.state.language ? this.$store.state.language : 'de'
    this.language = this.$i18n.locale
  },
  mounted () {
    this.$on('offline', () => {
        this.snackbar = true
        this.searchFavorites()
    })
  },
  methods: {
    goToHome: function() {
      this.$root.menuOpen = false
      if (this.$router.currentRoute.path != '/home') this.$router.push('/home')
    },
    searchFavorites: function() {
      this.$root.menuOpen = false
      this.$root.activeNavigation = 'menu'
      if (this.$router.currentRoute.path != '/favorites') this.$router.push('/favorites')
    },
    openMap: async function() {
      this.$root.menuOpen = false
      this.$root.projectViewState = 'map'
      this.$root.activeNavigation = 'map'
      
      if (this.$router.currentRoute.path != '/projects') this.$router.push('/projects')
    },
    openList: async function() {
      this.$root.menuOpen = false
      this.$root.projectViewState = 'list'
      this.$root.activeNavigation = 'list'
      
      if (this.$router.currentRoute.path != '/projects') this.$router.push('/projects')
    },
    openMenu: function() {
      
      if (!this.$root.menuOpen) {
        this.$root.menuOpen = true
        this.oldActiveNavigation = this.$root.activeNavigation
        this.$root.activeNavigation = 'menu'
      } else  {
        this.$root.menuOpen = false
        this.$root.activeNavigation = this.oldActiveNavigation
      }
    },
    closeMenu: function() {
      
      this.$root.menuOpen = false
      this.$root.activeNavigation = this.oldActiveNavigation
    },
    clickMenu: function(path) {
      this.$root.menuOpen = false

      this.$router.push(path)
    },
    clickInstall: async function() {
      
      if (window.deferredPrompt !== undefined && window.deferredPrompt !== null) {

        window.deferredPrompt.prompt()
        const { outcome } = await window.deferredPrompt.userChoice;

        if (outcome === 'accepted') {
            window.deferredPrompt = null;
        }
      }
    }
  },
  computed: {
    hasFavorites: function () {
      return (this.$store.state.favoriteProjects.length > 0) ? false : true
    }
  },
  watch: {
    'language': function(value) {
      console.log('changed language to', value)
      this.$i18n.locale = value
      this.$store.commit('setLanguage', value)
    }
  }
}
</script>

<style lang="scss">

.cookie__floating__buttons__button:hover {
 background-color: #3A4257 
}

.cookie__floating__buttons__button--accept {
 color: #3A4257
}

.tertiary-background {
  background-color: $tertiary !important;
}

.v-expansion-panel-content {
  flex-direction: column;
}

.wysiwyg-text {
  a {
    color: #FFFFFF;
  }
}

.textbox-text {
  white-space: pre;
}

/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('~@/assets/fonts/roboto-v29-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('~@/assets/fonts/roboto-v29-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('~@/assets/fonts/roboto-v29-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('~@/assets/fonts/roboto-v29-latin-100.woff') format('woff'), /* Modern Browsers */
       url('~@/assets/fonts/roboto-v29-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('~@/assets/fonts/roboto-v29-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('~@/assets/fonts/roboto-v29-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('~@/assets/fonts/roboto-v29-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('~@/assets/fonts/roboto-v29-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('~@/assets/fonts/roboto-v29-latin-300.woff') format('woff'), /* Modern Browsers */
       url('~@/assets/fonts/roboto-v29-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('~@/assets/fonts/roboto-v29-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('~@/assets/fonts/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('~@/assets/fonts/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('~@/assets/fonts/roboto-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('~@/assets/fonts/roboto-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('~@/assets/fonts/roboto-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('~@/assets/fonts/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('~@/assets/fonts/roboto-v29-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('~@/assets/fonts/roboto-v29-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('~@/assets/fonts/roboto-v29-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('~@/assets/fonts/roboto-v29-latin-500.woff') format('woff'), /* Modern Browsers */
       url('~@/assets/fonts/roboto-v29-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('~@/assets/fonts/roboto-v29-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
</style>