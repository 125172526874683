<template>
  <v-chip v-if="tag" label class="ma-1" small :outlined="outlined" :value="tag.id" :active-class="activeClass">
    <v-icon v-if="tag.icon" small class="pr-1">{{tag.icon}}</v-icon>
    <span class="tag-text">{{ translation }}</span>
  </v-chip>
</template>

<script>

export default {
  name: 'TagIcon',
  props:['tagId', 'outlined', 'activeClass'],
  data: () => ({
    tag: null
  }),
  async mounted() {
    this.tag = await this.$root.tags.getTagById(this.tagId)
  },
  computed: {
    translation: function () {
      let userLanguage = this.$store.state.language

      if(userLanguage == 'de') userLanguage = 'de-DE'
      if(userLanguage == 'en') userLanguage = 'en-US'
      
      let translations = this.tag.translations.find(element => element.languages_code == userLanguage)
      
      if (!translations) return 'undefined'

      return translations.name
    }
  }
}

</script>

<style lang="scss"> 

.tag-text {
  text-overflow:ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition:text-indent 7s linear;
}

</style>