import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Favorites from '../views/Favorites.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    alias: '/home',
    component: Home
  },
  {
    path: '/projects',
    name: 'ProjectOverview',
    component: () => import(/* webpackChunkName: "projects" */ '../views/ProjectsOverview.vue')
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: Favorites
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue')
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import(/* webpackChunkName: "documents" */ '../views/Documents.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: () => import(/* webpackChunkName: "imprint" */ '../views/Imprint.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/about',
    name: 'about-us',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
  },
  {
    path: '/your-project',
    name: 'your-project',
    component: () => import(/* webpackChunkName: "your-project" */ '../views/YourProject.vue')
  },
  {
    path: '/commodity-contributions',
    name: 'commodity-contributions',
    component: () => import(/* webpackChunkName: "commodity-contributions" */ '../views/CommodityContributions.vue')
  },
  {
    path: '/our-mission',
    name: 'our-mission',
    component: () => import(/* webpackChunkName: "our-mission" */ '../views/OurMission.vue')
  },
  {
    path: '/authority',
    name: 'auhority',
    component: () => import(/* webpackChunkName: "authority" */ '../views/Authority.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
/* DEUGGING
router.beforeEach((to, from, next) => {
  console.log('to: ', to)
  console.log('from: ', from)
  next()
})
*/
export default router
