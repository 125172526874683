import View from 'ol/View'
import {Map as OLMap} from 'ol'
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer'
import OSM from 'ol/source/OSM'
import {fromLonLat, transformExtent} from 'ol/proj'
import Feature from 'ol/Feature'
import {Circle as CircleStyle, Fill, Icon, Stroke, Style, Text} from 'ol/style'
import {Cluster, Vector as VectorSource} from 'ol/source'
import {createEmpty, extend, getHeight, getWidth} from 'ol/extent.js'
import GeoJSON from 'ol/format/GeoJSON.js'

export default class Map {

  constructor(DirectusInstance) {
    this.client = DirectusInstance
    this.features = {}
    this.clickFeature = null
    this.clickResolution = null
    this.map = null
    this.target = null
    this.layers = []
  }

  async initMap(target) {

    if (this.map) return null

    this.target = target
    this.map = new OLMap({
      target: this.target,
      layers: [
        new TileLayer({
          source: new OSM()
        }),
      ],
      enableRotation: false,
      view: new View({
        zoom: parseInt(process.env.VUE_APP_PROJECT_MAP_DEFAULT_ZOOM),
        minZoom: parseInt(process.env.VUE_APP_PROJECT_MAP_MIN_ZOOM),
        maxZoom: parseInt(process.env.VUE_APP_PROJECT_MAP_MAX_ZOOM),
        center: fromLonLat(JSON.parse(process.env.VUE_APP_PROJECT_MAP_CENTER_LON_LAT)),
        constrainResolution: true,
        extent: transformExtent(JSON.parse(process.env.VUE_APP_PROJECT_MAP_CENTER_EXTEND), 'EPSG:4326', 'EPSG:3857')
      }),
    })

    // turn mouse pointer into pointer icon
    this.map.on('pointermove', function(e) {
      var pixel = this.getEventPixel(e.originalEvent)
      var hit = this.hasFeatureAtPixel(pixel)
      this.getViewport().style.cursor = hit ? 'pointer' : ''
    })
    
    this.map.on('click', (event) => {
      
      let foundFeature = false
      
      this.map.forEachFeatureAtPixel(event.pixel,
                (features, layer) => {
                  const clusterMembers = features.get('features')
                  
                  // single feature
                  if (clusterMembers.length == 1) {
                    foundFeature = true
                    target.dispatchEvent(
                      new CustomEvent("foundFeature", {
                        detail: {project_id: clusterMembers[0].values_.project_id}
                      })
                    )
                  }
                  // cluster
                  else if(clusterMembers.length > 1) {
                    // Calculate the extent of the cluster members.
                    const extent = createEmpty()
                    clusterMembers.forEach((feature) =>
                      extend(extent, feature.getGeometry().getExtent())
                    );
                    const view = this.map.getView()
                    const resolution = this.map.getView().getResolution();
                    if (
                      view.getZoom() === view.getMaxZoom() ||
                      (getWidth(extent) < resolution && getHeight(extent) < resolution)
                    ) {
                      // Show an expanded view of the cluster members.
                      this.clickFeature = features[0]
                      this.clickResolution = resolution
                      //clusterCircles.setStyle(clusterCircleStyle)
                    } else {
                      // Zoom to the extent of the cluster members.
                      view.fit(extent, {duration: 500, padding: [50, 50, 50, 50]});
                    }
                  }
                },
                { layerFilter: (layer) => {
                    return (layer.type === new VectorLayer().type && layer.get('name') == 'resultLayer') ? true : false;
                  },
                  hitTolerance: 6 
                })
        
      // noting found on click position, might want to hide popup
      if (!foundFeature) {target.dispatchEvent(new CustomEvent("foundNoFeature"))}
    })
  }

  async addFeature(layer, feature) {
  
   if (this.features[layer] == undefined) this.features[layer]=Array()

   this.features[layer].push(new Feature(feature))

   return null
  }

  removeLayer(layer) {
    
    // clear features
    this.features[layer] = Array()

    // remove layer
    this.map.getLayers().forEach(entry => {
      if (entry.get('name') == layer) {
        this.map.removeLayer(entry)
      }
     })
  }

  async addLayer(layer) {

    const clusterSource = new Cluster({
      distance: 30,
      source: new VectorSource({features: this.features[layer]}),
    })

    this.map.addLayer(new VectorLayer({
      source: clusterSource,
      'name': layer,
      'style': this.getMarkerStyle
    }))
  }
  
  getMarkerStyle(feature) {

    let styles = []

    // icon cluster
    if (feature.get('features') && feature.get('features').length > 1) {
      const size = feature.get('features').length

      // basic feature circle
      styles.push(new Style({
        zIndex: 1,
        image: new CircleStyle({
                radius: 20,
                fill: new Fill({color: 'rgba(58, 66, 87, 1)'})
              })
      }))

      let iconCoordinates = {'0':[1, 1], '1':[0, 1], '2':[1, 0], '3':[0, 0]}

      // add feature icons if less than 4
      if(size <= 4) {
        
        let count = 0

        feature.get('features').forEach(element => {
          
          let icon = 'mdi-help-circle-outline' // default icon

          // overwrite default icon only if we knwo we have a better one
          if ([ 'mdi-silverware-variant', 'mdi-headset', 'mdi-account-cash', 'mdi-toilet', 
                'mdi-wifi-check', 'mdi-help-circle-outline', 'mdi-bed-outline', 'mdi-food-drumstick-outline', 
                'mdi-forum-outline', 'mdi-medical-bag', 'mdi-truck-check-outline', 'mdi-shower-head', 
                'mdi-white-balance-sunny', 'mdi-road-variant', 'mdi-needle', 'mdi-cart-outline',
                'mdi-human-child', 'mdi-tshirt-crew-outline', 'mdi-cash-off'].includes(element.values_.icon)) {
            icon = element.values_.icon
          }

          styles.push(
              new Style({ // icon
                geometry: feature.getGeometry(), // use cluster geometry here!
                zIndex: 2,
                image: new Icon({
                  anchor: iconCoordinates[count],
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'fraction',
                  src: 'icons/' + icon + '.png',
                  scale: 0.7,
                }),
              })
            )
            count++
        })
      }
      else { // display count if more than 4 icons
        styles.push(new Style({
          zIndex:2,
          text: new Text({
            text: size.toString(),
            fill: new Fill({color: '#fff'}),
            stroke: new Stroke({
                      color: 'rgba(0, 0, 0, 0.6)',
                      width: 3,
                    })
          })
        }))
      }

      return styles
    }
    
    // single icon
    if (feature.get('features')) feature = feature.get('features')[0]

    let icon = 'mdi-help-circle-outline' // default icon

    // overwrite default icon only if we knwo we have a better one
    if ([ 'mdi-silverware-variant', 'mdi-headset', 'mdi-account-cash', 'mdi-toilet', 
          'mdi-wifi-check', 'mdi-help-circle-outline', 'mdi-bed-outline', 'mdi-food-drumstick-outline', 
          'mdi-forum-outline', 'mdi-medical-bag', 'mdi-truck-check-outline', 'mdi-shower-head', 
          'mdi-white-balance-sunny', 'mdi-road-variant', 'mdi-needle', 'mdi-cart-outline',
          'mdi-human-child', 'mdi-tshirt-crew-outline'].includes(feature.values_.icon)) {
      icon = feature.values_.icon
    }
    
    return [new Style({ // basic marker
                geometry: feature.getGeometry(),
                zIndex:5,
                image: new Icon({
                  anchor: [0.5, 50],
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'pixels',
                  src: 'icons/MapMarker.png',
                  scale: 1,
                }),
            }),
            new Style({ // icon
              geometry: feature.getGeometry(),
              zIndex:6,
              image: new Icon({
                anchor: [0.5, 40],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                offset: [0, 0],
                src: 'icons/' + icon + '.png',
                scale: 1,
              }),
          })
        ]
  }

  async loadStopPoints() {

    // load geojson
    let temp = await fetch('stoppoints.geojson')
    .then(response => response.json())
    .then((data) => {
      return data
    })

    // add OEPNV Layer
    const oepnvVectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(temp,  {dataProjection:'EPSG:4326', featureProjection:'EPSG:3857'}),
    })

    const oepnvVectorLayer = new VectorLayer({
      'name': 'stopPoint',
      'source': oepnvVectorSource,
      'style': new Style({
        image: new CircleStyle({
            radius: 25,
            fill: new Fill({
                color: 'rgba(252,99,30,0.50)'
            }),
        }),
      })
    })

    await this.map.addLayer(oepnvVectorLayer)
  }
}