<template>
  <v-container>

    <img v-if="$vuetify.breakpoint.mobile" :src="require('@/assets/logos/' + logo_wide)" class="pt-2 pl-2" style="max-height: 100px;"/>
   
    <v-row class="ma-0">
      <v-col cols="12">
        <h1 class="text-center text-h5 text-md-h3">{{ $t('todays-offer') }}</h1>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn :small="$vuetify.breakpoint.mobile" color="primary" block @click="openMap()">
          <v-icon color="secondary" class="pr-2">mdi-map</v-icon>{{$t('find-offers')}}
        </v-btn>
      </v-col>
      <v-col cols="12" md="3" v-for="(tag, index) in home_search_tags" :key="index" >
        <v-btn :small="$vuetify.breakpoint.mobile" color="primary" block @click="searchTags(tag.id)">
          <v-icon color="secondary" class="pr-2">{{tag.icon}}</v-icon>{{$t(tag.icon)}}
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col cols="12" md="6" v-for="(entry, index) in translatedEntries" :key="index">
        <v-card color="tertiary"  class="fill-height">
          <v-card-title>{{entry.title}}<v-icon v-if="entry.icon" color="primary" style="position:absolute; right: 15px">mdi-{{entry.icon}}</v-icon></v-card-title>
          <v-card-text class="wysiwyg-text" v-html="entry.text" />
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card color="tertiary"  class="fill-height">
          <v-card-title>{{ $t('faq') }}</v-card-title>
          <v-card-text>
            <v-row align="center">
            <v-col cols="8" md="12">
              {{ $t('faq-text') }}
            </v-col>
            <v-col cols="4" md="12">
              <v-btn color="primary" class="white--text ml-2" rounded to="/faq">{{ $t('more') }}</v-btn>
            </v-col>
          </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="12" v-if="news">
        <v-card color="tertiary" class="fill-height">
          <v-card-title>News</v-card-title>
          <v-card-text v-if="news.length == 0">{{ $t('no-news')}}</v-card-text>
          <v-card-text v-for="(entry, index) in news" :key="index" style="cursor: pointer"  @click="openProjectDetails(entry)">
            <v-row align="center">
              <v-col cols="12">
                <v-icon v-if="entry.type == 'info'" class="pr-2" color="info">mdi-information-outline</v-icon>
                <v-icon v-if="entry.type == 'warn'" class="pr-2" color="warning">mdi-alert-outline</v-icon>
                <v-icon v-if="entry.type == 'alert'" class="pr-2" color="error">mdi-alert-circle-outline</v-icon>
                <b>{{entry.headline}}</b>
              </v-col>
              <v-col cols="12">
                {{entry.content}}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!--<v-row v-if="!$vuetify.breakpoint.mobile" class="ma-0">
      <v-col>
        <v-img :src="require('@/assets/logos/' + supporter_logo)" contain height="80px" />
      </v-col>
    </v-row>-->

    <v-overlay :value="showProjectDetail" :style="'height: calc(100vh - ' + ($vuetify.breakpoint.mobile ? '66' : '118') + 'px);'" :dark="false" color="white" opacity="100">
      <div :style="'height: calc(100vh - ' + ($vuetify.breakpoint.mobile ? '66' : '196') + 'px); overflow:auto;'">
        <ProjectDetail v-if="showProjectDetail" @closed="showProjectDetail = false" :project="project" :projectId="project.id" />
      </div>
    </v-overlay>

  </v-container>
</template>

<script>

import ProjectDetail from '@/components/ProjectDetail'

export default {
  name: 'Home',
  components: {
    ProjectDetail
  },
  data: () => ({
    home: [],
    news: [],
    home_search_tags: [],
    supporter_logo: process.env.VUE_APP_PROJECT_SUPPORTER_LOGO,
    logo_wide: process.env.VUE_APP_PROJECT_LOGO_WIDE,
    showProjectDetail: false,
    project: null
  }),
  async created() {
    
    let filterValues =  {'tenant_id': process.env.VUE_APP_PROJECT_TENANT_ID }
    
    let temp = await this.$client.items("home").readByQuery({ fields: ['*.*, translations.*.*'], sort: 'sort', filter: filterValues}) 
    this.home = temp.data

    filterValues =  { "_and": [
                        {"_or": [
                          {"project_id":{"tenant_id":{"_eq":process.env.VUE_APP_PROJECT_TENANT_ID}}},
                          {"tenant_id":{"_eq":process.env.VUE_APP_PROJECT_TENANT_ID}}
                        ]},
                        {"start_date": {"_lt":"$NOW"}},
                        {"end_date": {"_gt":"$NOW"}}
                      ]
                    }

    temp = await this.$client.items("news").readByQuery({ fields: ['*.*'], sort: 'sort', filter: filterValues}) 
    this.news = temp.data

    this.home_search_tags = require(process.env.VUE_APP_PROJECT_HOME_SEARCH_TAGS_FILE)
  },
  methods: {
    searchTags: function(a) {
      let params = null;

      if(Object.prototype.toString.call(a) === '[object Array]') {
         params= {'tags': a}
      }
      else params= {'tags': [a]}

      this.$router.push({name: 'ProjectOverview', params: params})
    },
    openProjectDetails: function(newsEntry) {
      if (newsEntry.project_id) {
        this.showProjectDetail = true
        this.project = newsEntry.project_id
      } 
    },
    openMap: async function() {
      this.$root.menuOpen = false
      this.$root.projectViewState = 'map'
      this.$root.activeNavigation = 'map'
      
      if (this.$router.currentRoute.path != '/projects') this.$router.push('/projects')
    },
  },
  computed: {
   translatedEntries: function() {

      if (!this.home) return []

      let userLanguage = this.$store.state.language

      if(userLanguage == 'de') userLanguage = 'de-DE'
      if(userLanguage == 'en') userLanguage = 'en-US'

      let translations = []

      this.home.forEach(item => {
         let temp = item.translations.find(element => element.languages_code.code == userLanguage) 
         translations.push({...temp, icon: item.icon})
      })

      return translations
    }
  }
}
</script>