import {fromLonLat, transformExtent} from 'ol/proj'

export default class Search {

  constructor(DirectusInstance) {
    this.client = DirectusInstance
    this.parameter = []
    this.results = []
    this.mapPOIs = []
    this.projectCache = {}
  }

  setParameter (parameter) {
    this.parameter = parameter
  }

  async getProjectById(id) {
    // return cache if found
    if(this.projectCache[id]) return this.projectCache[id]

    // get data from db
    await this.client.items("projects").readOne(id, { fields: ['id, name, claim, description, location, contact_name, email, opening_days, opening_text, date_updated, phone_number, spoken_languages, street, website, zipcode', 'main_tag.id', 'tags.tags_id', 'translations.*', 'opening_events.*']})
    .then(data => {
      this.projectCache[id] = data
    })

    return this.projectCache[id]
  }

  async performSearch() {

    this.results = []
    this.mapPOIs = []

    await this.client.items("projects").readByQuery({ fields: ['id, name, claim, location, main_tag.id, opening_days, tags.tags_id'], filter: this.parameter})
    .then(data => {
      this.results = data.data
    })
  }

  async getResults() {
    return this.results
  }

  async getResultPOIs() {
    let results = []

    for(const project of this.results) {
      if(project.location) {
        this.mapPOIs.push({
          project_id: project.id,
          name: project.name,
          claim:project.claim,
          main_tag: project.main_tag,
          location: fromLonLat([project.location.coordinates[0], project.location.coordinates[1]])
        })
      }
    }

    return this.mapPOIs
  }
}
