import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { Directus } from "@directus/sdk";
import i18n from './i18n'
import './registerServiceWorker';
Vue.config.productionTip = false
import VueOffline from 'vue-offline'

if (process.env.VUE_APP_SENTRY_DNS != 'false') {

  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", "strassenlotse-stuttgart.de", /^\//]
      }),
    ],
    logErrors: process.env.VUE_APP_SENTRY_LOG_ERROR,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring, 0.0 for none
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACKING_RATE
  });
}

// init directus connection
const client = new Directus(process.env.VUE_APP_DIRECTUS_URL)
Object.defineProperties(Vue.prototype, {
  $client: { value: client },
  $DIRECTUS_ASSET_URL: {value: process.env.VUE_APP_DIRECTUS_ASSETS_URL}
})

// offline helper
Vue.use(VueOffline,{
  mixin: false,
  storage:false
})

// vuex init
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    favoriteProjects: [],
    language: 'de'
  },
  mutations: {
    initialiseStore(state) {
      // load local storage if found
      if (localStorage.getItem('store')) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('store')))
        )
      }
    },
    toggleFavorit(state, project) {
      let index = state.favoriteProjects.findIndex(element => element.id == project.id)

      if (index > -1) { // found -> delete
        state.favoriteProjects.splice(index, 1)
      } else { // not found -> save
        state.favoriteProjects.push(project)
      }
    },
    setLanguage(state, language) {
      state.language = language
    }
  },
  getters: {
  }
})

store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('store', JSON.stringify(state));
})

// load custom classes
import Map from './libraries/Map';
import Search from './libraries/Search';
import Tags from './libraries/Tags';

new Vue({
  router,
  store,
  beforeCreate() {
		this.$store.commit('initialiseStore')
	},
  vuetify,
  render: h => h(App),
  i18n,
  data() {
    return {
      GlobalSearch: new Search(client),
      Map: new Map(client),
      tags: new Tags(client),
      projectViewState: 'map',
      menuOpen: false,
      activeNavigation: 'home',
      position: {lat: JSON.parse(process.env.VUE_APP_PROJECT_MAP_CENTER_LON_LAT)[1], lon: JSON.parse(process.env.VUE_APP_PROJECT_MAP_CENTER_LON_LAT)[0]}
    }
  }
}).$mount('#app')
