<template>
  <v-container class="pa-1 tertiary-background">
    <h1 class="text-center">{{$t('my-favorites')}}</h1>

    <v-row dense v-if="favorites">
      <v-col cols="12" v-for="project in favorites" :key="project.id">
        <ProjectElement class="container" :project='project' />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import ProjectElement from '@/components/ProjectElement'

export default {
  name: 'Favorites',
  components: {
    ProjectElement,
  },
  data: () => ({
  }),
  computed: {
    favorites: function () {
      return (this.$store.state.favoriteProjects.length > 0) ? this.$store.state.favoriteProjects : []
    }
  },
  
}
</script>

<style lang="scss">


</style>