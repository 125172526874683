<template>
  <v-card v-if="project" color="primary" class="container white--text">
    <v-row no-gutters justify="center">
      <v-col cols="5" md="6">
        <TagIcon :tagId="project.main_tag.id" />
      </v-col>
      <v-spacer />
      <v-col cols="4" md="3" class="px-3">
        <v-btn :small="$vuetify.breakpoint.mobile" @click="toggleFavorit(project.id)" outlined class="mr-2" color="secondary" style="position:absolute; right:0px;">
          <v-icon v-if="isFavorit" color="secondary">mdi-star</v-icon>
          <v-icon v-else color="secondary">mdi-star-outline</v-icon>
          {{ $t('favorites') }}
        </v-btn>
      </v-col>

      <v-col cols="12" class="px-1 mt-2" @click="showProjectDetail = true" style="cursor: pointer">
        <div class="text-body-2 font-weight-bold">{{project.name}}</div>
        <div class="text-body-2">{{project.claim}}</div>
      </v-col>

     <v-col v-if="project.opening_days" cols="12" class="pl-1">
        <span class="caption" style="word-wrap: normal">
          <v-icon class="my-2 mr-1" small color="secondary">mdi-clock</v-icon>
          <span class="mr-1 caption" v-if="project.opening_days.includes('monday')">MO</span>
          <span class="mr-1 caption" v-if="project.opening_days.includes('tuesday')">DI</span>
          <span class="mr-1 caption" v-if="project.opening_days.includes('wednesday')">MI</span>
          <span class="mr-1 caption" v-if="project.opening_days.includes('thursday')">DO</span>
          <span class="mr-1 caption" v-if="project.opening_days.includes('friday')">FR</span>
          <span class="mr-1 caption" v-if="project.opening_days.includes('saturday')">SA</span>
          <span class="mr-1 caption" v-if="project.opening_days.includes('sunday')">SO</span>
        </span>
     </v-col>

      <v-col v-if="project.tags.length > 0" cols="12">
        <TagIcon :tagId="tag.tags_id" v-for="tag in project.tags" :key="tag.tags_id.name" />
      </v-col>

      <v-col v-if="news && news.length > 0" cols="12" @click="showProjectDetail = true">
        <span v-for="(entry, index) in news" :key="index">
          <v-icon v-if="entry.type == 'info'" class="pr-2" color="info">mdi-information-outline</v-icon>
          <v-icon v-if="entry.type == 'warn'" class="pr-2" color="warning">mdi-alert-outline</v-icon>
          <v-icon v-if="entry.type == 'alert'" class="pr-2" color="error">mdi-alert-circle-outline</v-icon>  
          <b>{{entry.headline}}</b>
        </span>
      </v-col>

      <v-col v-if="project.location" cols="3">
        <v-icon color="secondary">mdi-map-marker-outline</v-icon>
        <span class="text-body-2 pt-1" style="position:absolute">{{calculateDistanceToTarget(project.location.coordinates[1], project.location.coordinates[0])}} km</span>
      </v-col>
      <v-spacer />
      <v-col cols="4">
        <v-btn :small="$vuetify.breakpoint.mobile" @click="showProjectDetail = true" class="float-right" outlined color="secondary">
          {{ $t('more') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-overlay :value="showProjectDetail" :style="'height: calc(100vh - ' + ($vuetify.breakpoint.mobile ? '66' : '118') + 'px);'" :dark="false" color="white" opacity="100">
      <div :style="'height: calc(100vh - ' + ($vuetify.breakpoint.mobile ? '66' : '196') + 'px); overflow-y:auto;overflow-x:hidden'">
        <ProjectDetail v-if="showProjectDetail" @closed="showProjectDetail = false" :project="project" :projectId="project.id" />
      </div>
    </v-overlay>
  </v-card>
</template>

<script>

import ProjectDetail from '@/components/ProjectDetail'
import TagIcon from '@/components/TagIcon'

export default {
  name: 'ProjectElement',
  props: ['project', 'news'],
  components: {
    ProjectDetail,
    TagIcon
  },
  data: () => ({
    mainTags: [],
    showProjectDetail:false
  }),
  mounted() {

  },
  methods: {
    calculateDistanceToTarget: function (targetLat, targetLon) {

      // user position
      var userLat = this.$root.position.lat 
      var userLon = this.$root.position.lon

      var R = 6371; // Radius of the earth in km
      var dLat = (userLat-targetLat)* (Math.PI/180);  // deg2rad below
      var dLon = (userLon-targetLon)* (Math.PI/180); 
      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.cos(targetLat* (Math.PI/180)) * Math.cos(userLat* (Math.PI/180)) * 
              Math.sin(dLon/2) * Math.sin(dLon/2); 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c; // Distance in km
      return Math.ceil(d);
    },
    async toggleFavorit(projectId) {

      let project = await this.$root.GlobalSearch.getProjectById(projectId)
      this.$store.commit('toggleFavorit', project)
    }
  },
  computed: {
    isFavorit: function() {
      return (this.$store.state.favoriteProjects && this.$store.state.favoriteProjects.findIndex(element => element.id == this.project.id)  > -1) ? true : false
    }
  }
}
</script>